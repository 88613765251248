<template>
	<v-dialog
		fullscreen
		:value="value"
		@input="$emit('input', $event)"
		@keydown.esc="HideImageDialog"
		persistent
		:scrollable="false"
	>
		<v-toolbar dense float color="" v-if="carouselCurrentIndex >= 0">
			<v-btn icon @click="HideImageDialog">
				<v-icon>fas fa-arrow-left</v-icon>
			</v-btn>

			<v-toolbar-title>{{ selectedFileName }}</v-toolbar-title>

			<v-spacer></v-spacer>
			<ApproveStatusIcon
				class="mr-2"
				v-if="Files[carouselCurrentIndex].SgpApprovalOfferItem"
				:size="24"
				:approve-status="
					Files[carouselCurrentIndex].SgpApprovalOfferItem.ApprovalStatus
				"
			></ApproveStatusIcon>
			<template>
				{{ $t("common.select") }}:
				<v-btn
					:disabled="
						[
							SgpApprovalOfferItemStatusEnum.REJECTED,
							SgpApprovalOfferItemStatusEnum.NOT_SET,
						].includes(
							Files[carouselCurrentIndex].SgpApprovalOfferItem?.ApprovalStatus
						)
					"
					icon
					@click="
						Files[carouselCurrentIndex].Selected =
							!Files[carouselCurrentIndex].Selected
					"
				>
					<v-icon v-if="!Files[carouselCurrentIndex].Selected" color="">
						far fa-circle
					</v-icon>
					<v-icon v-else color="blue"> fas fa-check-circle </v-icon>
				</v-btn>

				<v-btn icon color="info" @click="GetBlobProperties">
					<v-icon>fas fa-info-circle</v-icon>
				</v-btn>

				<v-dialog v-model="showNavigationMenu" width="400">
					<v-card>
						<v-card-title
							><v-icon color="info" class="mr-2">fas fa-info-circle</v-icon
							>Info</v-card-title
						>
						<v-card-text v-if="SelectedFileInfo">
							<span class="text-subtitle-2">{{ selectedFileName }}</span>
							<br />
							<span class="font-weight-bold">Dimensioni:</span>
							{{ SelectedFileInfo.Metadata.Width }}x{{
								SelectedFileInfo.Metadata.Height
							}}
							{{ SelectedFileInfo.FileSize | formatFileSize }}
							<br />
							<span class="font-weight-bold">Fotografo:</span>
							{{ SelectedFileInfo.Metadata.Artist | emptyField }}
						</v-card-text>
					</v-card>
				</v-dialog>
			</template>
		</v-toolbar>

		<v-container fill-height fluid class="black fullscreenContainer">
			<v-row align="center" justify="center">
				<v-col class="pa-0" cols="12">
					<v-carousel
						height="auto"
						v-bind:value="carouselCurrentIndex"
						@change="OnCarouselCurrentIndex"
						:continuous="false"
						:hide-delimiters="true"
						class=""
						prev-icon="fas fa-angle-left"
						next-icon="fas fa-angle-right"
					>
						<v-carousel-item v-for="(file, i) in Files" :key="i">
							<v-row
								class="fill-height ma-0 fullscreenContainer"
								align="center"
								justify="center"
							>
								<!-- <OfferImage
									v-if="file.MediaTypeId === 1"
									class="carouselImage"
									contain
									:aspect-ratio="null"
									:file="file"
								></OfferImage> -->
								<div v-if="file.MediaTypeId === 2">
									<!-- <span class="text-white">{{ file }}</span> -->
									<vueVimeoPlayer
										class="vimeo"
										:video-url="file.ExternalMediaUrl"
									>
									</vueVimeoPlayer>
								</div>

								<v-img
									v-if="file.MediaTypeId === 1"
									class="carouselImage"
									contain
									:key="file.MediaId"
									:src="file.src + (SasToken === null ? '' : SasToken)"
									><template v-slot:placeholder>
										<v-row
											class="fill-height ma-0"
											align="center"
											justify="center"
										>
											<v-progress-circular
												indeterminate
												color="grey lighten-2"
											></v-progress-circular>
										</v-row>
									</template>
								</v-img>
							</v-row>
						</v-carousel-item>
					</v-carousel>
				</v-col>
			</v-row>
		</v-container>
	</v-dialog>
</template>
<script>
import { vueVimeoPlayer } from "vue-vimeo-player";
import { mapActions, mapGetters } from "vuex";
import CrudClient from "@/services/CrudClient/";
import { SgpApprovalOfferItemStatusEnum } from "@/views/Events/components/sgpApprovalOfferItemStatus";
import ApproveStatusIcon from "@/views/ThirdPartyApprover/ApproveStatusIcon.vue";

export default {
	name: "OfferGalleryDialog",
	components: {
		ApproveStatusIcon,
		vueVimeoPlayer,
		//	OfferImage,
	},
	props: {
		value: { type: Boolean, required: true },
		Files: { type: Array, required: true },
		carouselCurrentIndex: { type: Number },
	},
	data() {
		return {
			showNavigationMenu: false,
			SelectedFileInfo: null,
		};
	},
	computed: {
		...mapGetters(["SasToken"]),

		selectedFileName() {
			if (this.Files[this.carouselCurrentIndex]) {
				return this.Files[this.carouselCurrentIndex].FileName;
			}
			return null;
		},
	},
	watch: {
		value: async function (val) {
			this.$log.debug("watch OfferGalleryDialog value");
			this.$log.debug(val);

			// if (!val) this.ClearNewUserDialogForm();
		},
	},
	created() {
		this.SgpApprovalOfferItemStatusEnum = SgpApprovalOfferItemStatusEnum;
		this.OffersService = new CrudClient("SGP/Client/Offers");
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		HideImageDialog() {
			this.$emit("input", false);
		},

		OnCarouselCurrentIndex(param) {
			this.$emit("carousel-current-index-changed", param);

			//
		},
		carouselResize() {
			this.$log.debug("carouselResize");
		},

		async GetBlobProperties() {
			this.SelectedFileInfo = null;

			const selectedFile = this.Files[this.carouselCurrentIndex];
			try {
				this.SelectedFileInfo = await this.OffersService.Get(
					selectedFile.OfferId,
					selectedFile.MediaId + "/BlobProperties"
				);
				this.showNavigationMenu = true;
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("error.cannotLoadData") });
			}

			// this.$log.debug(res);
		},
	},
};
</script>
<style lang="scss">
.vimeo iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
</style>
<style lang="scss" scoped>
.fullscreenContainer {
	height: calc(100vh - 48px) !important;
	/* max-height: calc(100vh-48px); */
}

.carouselImage {
	width: 100%;
	height: 100%;
	object-fit: contain;
	display: block;
}
</style>
