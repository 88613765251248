<template>
	<v-card>
		<v-card-text>
			<confirm ref="confirm"></confirm>
			<v-container fluid>
				<div class="basketLargeLabel mb-2">
					{{ isVideo ? $t("ApproveVideosTitle") : $t("ApprovePhotosTitle") }}
				</div>
				<v-row dense>
					<v-divider class="my-3"></v-divider>
				</v-row>
				<v-row dense>
					<v-col>
						<v-checkbox v-model="requestRetouching" :label="$t('requestRetouching')" hide-details
							class="mt-1" @change="PrepareRequestMessage"></v-checkbox>
						<v-alert type="info" color="blue" class="mt-2" v-if="requestRetouching">
							{{ $t("postProductionHint") }}
						</v-alert>

						<v-textarea v-if="requestRetouching" v-model="retouchingNotes" counter="1000" solo></v-textarea>
					</v-col>
				</v-row>
				<v-row dense>
					<v-divider class="my-3"></v-divider>
				</v-row>
				<v-row dense>
					<v-col>
						<v-btn color="primary" :loading="SendingOrder" x-large block @click="CreateApprovalOrder">
							<span v-if="requestRetouching">
								{{ $t("sendWithRetouchingRequest") }}</span>
							<span v-else>
								<v-icon left>far fa-check</v-icon> {{ $t("approveOffer") }}</span>
						</v-btn>
					</v-col>
				</v-row>
			</v-container>
		</v-card-text>
	</v-card>
</template>
<script>

import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";

import Confirm from "@/components/Shared/Common/Confirm";

export default {
	components: {
		Confirm
	},
	data() {
		return {
			SendingOrder: false,
			requestRetouching: false,
			retouchingNotes: null,
			showTermsDialog: false,
			showPaymentStep: false,
		}
	},
	props: {
		SelectedItems: {
			type: Array,
			required: true,
		},
		OfferDetails: {
			type: Object,
			required: true,
		},
		isVideo: {
			type: Boolean,
			default: false
		}
	},
	created() {
		this.ClientOrdersService = new CrudClient("SGP/Client/Orders");
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		PrepareRequestMessage(val) {
			if (!val) return;
			this.retouchingNotes = "";
			for (let i = 0; i < this.SelectedItems.length; i++) {
				this.retouchingNotes += this.SelectedItems[i].FileName + ": \n\n";
			}
		},

		async CreateApprovalOrder() {
			if (
				!(await this.$refs.confirm.open(
					this.$t("confirmSendApprovalOrderTextTitle"),
					this.$t("confirmSendApprovalOrderText")
				))
			) {
				return;
			}

			this.SendingOrder = true;
			try {
				const dto = {
					EventId: this.OfferDetails.EventId,
					OfferId: this.OfferDetails.OfferId,
					RequestRetouching: this.requestRetouching,
					RetouchingNotes: this.retouchingNotes,
				};

				// create draft order
				const res = await this.ClientOrdersService.Post(
					null,
					dto,
					"InitializeOrder",
					true
				);

				// add order items
				for (let i = 0; i < this.SelectedItems.length; i++) {
					const itemDto = {
					// FileName: this.SelectedItems[i].DisplayName,
						MediaId: this.SelectedItems[i].MediaId,
						MediaTypeId: this.SelectedItems[i].MediaTypeId,
						LicenseId: null,
						Price: 0,
					};

					const orderItem = await this.ClientOrdersService.Post(
						res.OrderId,
						itemDto,
						"ordermedia",
						true
					);

					this.$log.debug(orderItem);
				}

				// deliver the order
				await this.ClientOrdersService.Post(
					res.OrderId,
					null,
					"sendapproval",
					false
				);

				// this.snackSuccess({ Text: this.$t("orderCreated") });

				this.$emit("input", false);

				if (!this.requestRetouching) {
					this.ClientOrdersService.Post(res.OrderId, null, "zip", false);
				}
				this.$router.push({
					path: `/Clients/Orders/${res.OrderId}`,
					query: { ShowThanksForApproval: true },
				});
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("error.cannotSaveData") });
			} finally {
				this.SendingOrder = false;
			}
		}
	}
}
</script>
<i18n>
{
	"it": {
		"confirmSendApprovalOrderTextTitle": "Invia la selezione",
		"confirmSendApprovalOrderText": "Sei sicuro di voler inviare la selezione?",
		"termsError": "Devi accettare i termini e le condizioni",
		"retouchingNotes": "Descrizione",
		"requestRetouching": "Richiedi post-produzione",
		"postProductionHint":"Scrivi le modifiche richieste per ogni immagine",
		"sendWithRetouchingRequest": "Richiedi le modifiche",
		"approveOffer": "Approva",
		"offerReceived": "Ricevuto il ",
		"proceedToOrder": "Procedi all'ordine",
		"proceed": "Procedi",
		"youAreAboutToApprove": "Stai per approvare {count} foto",
		"youAreAboutToApproveVideo": "Stai per approvare {count} video",
		"orderSummary": "Riepilogo",
		"ApprovePhotosTitle": "Approva le foto",
		"ApproveVideosTitle": "Approva i video"
	},
	"en": {
		"confirmSendApprovalOrderTextTitle": "Send selected images",
		"confirmSendApprovalOrderText": "Are you sure you want to submit your selection?",
		"termsError": "You must accept the terms and conditions",
		"retouchingNotes": "Description",
		"requestRetouching": "Post-production request",
		"postProductionHint":"Write the changes required for each image",
		"sendWithRetouchingRequest": "Request changes",
		"approveOffer": "Approve",
		"titleSteps": "How does it work?",
		"offerReceived": "Received on ",
		"proceedToOrder": "Proceed to order",
		"proceed": "Proceed",
		"youAreAboutToApprove": "You are about to approve {count} photo(s)",
		"youAreAboutToApproveVideo": "You are about to approve {count} video(s)",
		"acceptTermsConditions": "Accept terms and conditions",
		"orderSummary": "Order Summary",
		"ApprovePhotosTitle": "Approve the photos",
		"ApproveVideosTitle": "Approve the videos"
	}
}
</i18n>
