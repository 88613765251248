<template>
	<v-dialog
		:value="value"
		@input="$emit('input', $event)"
		fullscreen
		persistent
	>
		<confirm ref="confirm"></confirm>
		<v-card>
			<v-card-title class="text-h5 grey lighten-3">
				<template v-if="offerDetails.OfferType === SgpOfferTypeEnum.OFFER">
					<span v-if="isVideoOrder">
						{{ $t("youAreAboutToBuyVideo", { count: SelectedItems.length }) }}
					</span>
					<span v-else>
						{{ $t("youAreAboutToBuy", { count: SelectedItems.length }) }}
					</span>
				</template>
				<template v-if="offerDetails.OfferType === SgpOfferTypeEnum.APPROVAL">
					<span v-if="isVideoOrder">
						{{
							$t("youAreAboutToApproveVideo", { count: SelectedItems.length })
						}}
					</span>
					<span v-else>
						{{ $t("youAreAboutToApprove", { count: SelectedItems.length }) }}
					</span>
				</template>

				<v-spacer></v-spacer>
				<v-btn
					text
					:disabled="SendingOrder"
					large
					@click="
						$emit('input', false);
						showPaymentStep = false;
					"
					>{{ $t("common.cancel") }}</v-btn
				>
			</v-card-title>

			<v-container fluid>
				<v-row class="basketLabel">
					<v-col cols="12" md="6" lg="8">
						<PhotoGrid
							ref="photoGrid"
							:Files="SelectedItems"
							class="mb-3"
							:targetHeight="$vuetify.breakpoint.xs ? 140 : 200"
						>
							<template v-slot:cell="{ file }">
								<OfferImage v-if="file.MediaTypeId === 1" :file="file">
									<div
										class="pa-3 black white--text"
										style="opacity: 0.6"
										v-if="requestRetouching"
									>
										{{ file.FileName }}
									</div>
								</OfferImage>
								<VimeoThumbnail
									v-else
									:src="file.ExternalPreviewUrl"
									:alt="file.FileName"
								>
								</VimeoThumbnail>
							</template>
						</PhotoGrid>
					</v-col>
					<v-col
						v-if="SelectedLicense && !showPaymentStep"
						cols="12"
						md="6"
						lg="4"
					>
						<v-card outlined>
							<v-card-text>
								<div class="basketLargeLabel mb-2">
									{{ $t("orderSummary") }}
								</div>
								<v-row dense>
									<v-divider class="my-3"></v-divider>
								</v-row>
								<div class="basketLabel mb-2">{{ $t("sgp.license") }}:</div>
								<SelectLicenseRadioGroup
									v-model="selectedLicenseId"
									:OfferDetails="offerDetails"
								>
								</SelectLicenseRadioGroup>
								<v-row dense>
									<v-divider class="my-3"></v-divider>
								</v-row>
								<template v-if="clientSettings.OpenAccount && !isVideoOrder">
									<v-row dense>
										<v-col>
											<v-checkbox
												v-model="requestRetouching"
												:label="$t('requestRetouching')"
												hide-details
												class="mt-1"
												@change="PrepareRequestMessage"
											></v-checkbox>

											<v-alert
												type="info"
												color="blue"
												class="mt-2"
												v-if="requestRetouching"
											>
												{{ $t("postProductionHint") }}
											</v-alert>

											<v-textarea
												v-if="requestRetouching"
												v-model="retouchingNotes"
												counter="1000"
												solo
											></v-textarea>
										</v-col>
									</v-row>
									<v-row dense>
										<v-divider class="my-3"></v-divider>
									</v-row>
								</template>

								<v-row dense>
									<v-col
										><span class="basketLabel"
											>{{ $t("common.quantity") }}:</span
										></v-col
									>
									<v-col class="text-right">
										<span class="basketValue">{{ SelectedItems.length }}</span>
									</v-col>
								</v-row>
								<v-row dense>
									<v-divider class="my-3"></v-divider>
								</v-row>

								<v-row dense>
									<v-col
										><span class="basketLabel"
											>{{ $t("common.total") }}
											<span style="font-size:70%">{{
												$t("vatNotIncluded")
											}}</span
											>:</span
										></v-col
									>
									<v-col class="text-right">
										<span class="basketValue">
											{{ OrderTotal | formatCurrency }}
										</span>
									</v-col>
								</v-row>
								<v-row dense>
									<v-divider class="my-3"></v-divider>
								</v-row>
								<v-row dense>
									<v-checkbox
										v-model="termsAgreed"
										:error-messages="checkboxTermsError"
										hide-details="auto"
									>
										<template #label>
											<span>
												<a @click.stop.prevent="showTermsDialog = true">
													{{ $t("acceptTermsConditions") }}
												</a>

												<v-dialog
													v-model="showTermsDialog"
													scrollable
													max-width="80%"
												>
													<v-card>
														<v-card-text class="pt-0">
															<v-row>
																<v-col
																	cols="12"
																	class="text-justify"
																	style="overflow-y: auto"
																>
																	<TermsConditions></TermsConditions>
																</v-col>
															</v-row>
														</v-card-text>
														<v-card-actions>
															<v-spacer></v-spacer>
															<v-btn
																color="primary"
																@click="showTermsDialog = false"
																>{{ $t("common.close") }}</v-btn
															>
														</v-card-actions>
													</v-card>
												</v-dialog>
											</span>
										</template>
									</v-checkbox>
								</v-row>
								<v-row>
									<v-col>
										<v-btn
											v-if="clientSettings.OpenAccount"
											color="primary"
											:loading="SendingOrder"
											x-large
											block
											@click="CreateOrder"
											>{{ $t("completeOrder") }}</v-btn
										>
										<v-btn
											v-else
											color="primary"
											:loading="SendingOrder"
											x-large
											block
											@click="LoadPaymentScreen"
										>
											{{ $t("common.continue") }}
										</v-btn>
									</v-col>
								</v-row>
							</v-card-text>
						</v-card>
					</v-col>
					<v-col cols="12" md="6" lg="4" v-if="showPaymentStep">
						<PaymentCard
							:SendingOrder="SendingOrder"
							:OrderTotal="OrderTotal"
							:VAT="OrderTotal * 0.22"
							@cancel="showPaymentStep = false"
							@paymentComplete="PayPalSendOrder"
						>
						</PaymentCard>
					</v-col>
					<v-col v-if="offerDetails.OfferType === SgpOfferTypeEnum.APPROVAL">
						<ApprovalOrderCard
							:SelectedItems="SelectedItems"
							:OfferDetails="offerDetails"
							:isVideo="isVideoOrder"
						>
						</ApprovalOrderCard>
					</v-col>
				</v-row>
			</v-container>
		</v-card>
	</v-dialog>
</template>
<script>
import SelectLicenseRadioGroup from "./SelectLicenseRadioGroup";
import ApprovalOrderCard from "./ApprovalOrderCard";
import TermsConditions from "@/views/StaticContent/TermsOfUse.vue";
import PaymentCard from "./PaymentCard.vue";
import Confirm from "@/components/Shared/Common/Confirm";
import CrudClient from "@/services/CrudClient/";
import { mapGetters, mapActions } from "vuex";

import { SgpOfferTypeEnum } from "../../Events/components/sgpOfferTypesEnum.js";
import { SgpOfferStatusIdEnum } from "../../Events/components/sgpOfferStatus";
import PhotoGrid from "@/components/Shared/UI/PhotoGrid.vue";

import OfferImage from "@/components/Shared/UI/OfferImage.vue";
import VimeoThumbnail from "@/components/Shared/UI/vimeoThumbnail.vue";
import { SgpApprovalOfferItemStatusEnum } from "@/views/Events/components/sgpApprovalOfferItemStatus";

export default {
	components: {
		Confirm,
		TermsConditions,
		PaymentCard,
		PhotoGrid,
		OfferImage,
		VimeoThumbnail,
		SelectLicenseRadioGroup,
		ApprovalOrderCard
	},
	data() {
		return {
			selectedLicenseId: null,
			termsAgreed: false,
			checkboxTermsError: null,
			SendingOrder: false,

			requestRetouching: false,
			retouchingNotes: null,
			showTermsDialog: false,
			showPaymentStep: false
		};
	},
	props: {
		value: { type: Boolean },
		SelectedItems: { type: Array, required: true },
		clientSettings: { type: Object, required: true },
		offerDetails: { type: Object, required: true }
	},
	computed: {
		...mapGetters(["SasToken"]),
		SelectedLicense() {
			if (!this.selectedLicenseId) return null;

			return this.offerDetails.SGPOfferPrices.find(
				op => op.LicenseId === this.selectedLicenseId
			);
		},
		OrderTotal() {
			return this.SelectedLicense.Price * this.SelectedItems.length;
		},

		OfferId() {
			return parseInt(this.$route.params.offerId);
		},
		isVideoOrder() {
			if (this.SelectedItems.length === 0) return false;

			return this.SelectedItems[0].MediaTypeId === 2;
		}
	},
	watch: {
		value: {
			immediate: true,
			handler: async function(value) {
				this.$log.debug("showCreateOrderDialog watch: " + value);
				if (value) {
					this.InitRetouchingRequest();
					this.$log.debug(this.SelectedItems);
					// because of the animation effects, nextTick does not work here
					setTimeout(() => {
						this.showPhotoGrid = true;
						if (this.$refs.photoGrid) this.$refs.photoGrid.calculateGridView();
					}, 400);
				} else {
					this.ResetForm();
				}
			}
		}
	},

	created() {
		this.OffersService = new CrudClient("SGP/Client/Offers");
		this.ClientOrdersService = new CrudClient("SGP/Client/Orders");

		this.SgpOfferTypeEnum = SgpOfferTypeEnum;
		this.SgpOfferStatusIdEnum = SgpOfferStatusIdEnum;
		this.SgpApprovalOfferItemStatusEnum = SgpApprovalOfferItemStatusEnum;
	},

	async mounted() {
		if (this.offerDetails.OfferType !== SgpOfferTypeEnum.OFFER) {
			return;
		}

		const clientDefaultLicenseId = this.clientSettings.DefaultLicenseId;

		const isDefaultLicenseFound = this.offerDetails.SGPOfferPrices.find(
			cp => cp.LicenseId === clientDefaultLicenseId
		);
		// if the client default license does not exist or is not in the available licenses, set the first one
		this.selectedLicenseId = isDefaultLicenseFound
			? clientDefaultLicenseId
			: this.offerDetails.SGPOfferPrices[0].LicenseId;
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		InitRetouchingRequest() {
			if (this.offerDetails.EnableThirdPartyApproval) {
				// Check if any SGPOfferItems have SgpApprovalOfferItems with ApprovalStatus === 3 (request retouching)
				const hasRetouchingRequests = this.SelectedItems.some(
					item =>
						item?.SgpApprovalOfferItem.ApprovalStatus ===
						SgpApprovalOfferItemStatusEnum.RETOUCHING_REQUESTED
				);

				if (hasRetouchingRequests) {
					this.requestRetouching = true;
					this.PrepareRequestMessage(true);
				}
			}
		},

		PrepareRequestMessage(val) {
			if (!val) return;
			this.retouchingNotes = "";
			for (let i = 0; i < this.SelectedItems.length; i++) {
				this.retouchingNotes +=
					this.SelectedItems[i].FileName +
					": " +
					(this.SelectedItems[i].SgpApprovalOfferItem?.RetouchingNotes ?? "") +
					"\n\n";
			}
		},

		async CreateOrder() {
			if (!this.termsAgreed) {
				this.checkboxTermsError = this.$t("termsError");
				return;
			}

			if (
				!(await this.$refs.confirm.open(
					this.$t("confirmSendOrderTextTitle"),
					this.$t("confirmSendOrderText")
				))
			) {
				return;
			}

			await this.SendOrder();
		},

		async SendOrder(payPalTransaction) {
			this.SendingOrder = true;
			try {
				const dto = {
					EventId: this.EventId,
					OfferId: this.OfferId,
					RequestRetouching: this.requestRetouching,
					RetouchingNotes: this.retouchingNotes
				};

				// create draft order
				const res = await this.ClientOrdersService.Post(
					null,
					dto,
					"InitializeOrder",
					true
				);

				// add order items
				for (let i = 0; i < this.SelectedItems.length; i++) {
					const itemDto = {
						// FileName: this.SelectedItems[i].DisplayName,
						MediaId: this.SelectedItems[i].MediaId,
						MediaTypeId: this.SelectedItems[i].MediaTypeId,
						LicenseId: this.selectedLicenseId,
						Price: this.SelectedLicense.Price
					};

					// deliver the order
					const orderItem = await this.ClientOrdersService.Post(
						res.OrderId,
						itemDto,
						"ordermedia",
						true
					);

					this.$log.debug(orderItem);
				}

				if (!payPalTransaction) {
					await this.ClientOrdersService.Post(res.OrderId, null, "send", false);
				} else {
					await this.ClientOrdersService.Post(
						res.OrderId,
						payPalTransaction,
						"CompleteWithPayment",
						false
					);
				}

				this.$emit("input", false);

				if (!this.requestRetouching) {
					this.ClientOrdersService.Post(res.OrderId, null, "zip", false);
				}
				this.$router.push({
					path: `/Clients/Orders/${res.OrderId}`,
					query: { ShowThanks: true }
				});
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("error.cannotSaveData") });
			} finally {
				this.SendingOrder = false;
			}
		},

		async PayPalSendOrder(event) {
			const dto = {};
			dto.PayPalId = event.id;
			dto.PayPalState = event.state;

			await this.SendOrder(dto);
		},

		LoadPaymentScreen() {
			if (!this.termsAgreed) {
				this.checkboxTermsError = this.$t("termsError");
				return;
			}

			this.showPaymentStep = true;
		},

		ResetForm() {
			this.termsAgreed = false;
			this.checkboxTermsError = null;
			this.requestRetouching = false;
			this.retouchingNotes = null;

			this.showPhotoGrid = false;
		}
	}
};
</script>
<i18n>
{
	"it": {
		"pageTitle": "Dettaglio Proposta",
		"completeOrder": "Acquista",
		"confirmSendOrderTextTitle": "Completa l'ordine",
		"confirmSendOrderText": "Sei sicuro di voler inviare l'ordine?",
		"confirmSendApprovalOrderTextTitle": "Invia la selezione",
		"confirmSendApprovalOrderText": "Sei sicuro di voler inviare la selezione?",
		"termsError": "Devi accettare i termini e le condizioni",
		"retouchingNotes": "Descrizione",
		"requestRetouching": "Richiedi post-produzione",
		"postProductionHint":"Scrivi le modifiche richieste per ogni immagine",
		"sendWithRetouchingRequest": "Richiedi le modifiche",
		"approveOffer": "Approva",
		"titleSteps": "Come funziona?",
		"revokedOffer": "La proposta non è più attiva.",
		"offerReceived": "Ricevuto il ",
		"proceedToOrder": "Procedi all'ordine",
		"proceed": "Procedi",
		"youAreAboutToBuy": "Stai per comprare {count} foto",
		"youAreAboutToApprove": "Stai per approvare {count} foto",
		"youAreAboutToBuyVideo": "Stai per comprare {count} video",
		"youAreAboutToApproveVideo": "Stai per approvare {count} video",
		"acceptTermsConditions": "Accetto i termini e le condizioni",
		"priceExcludingVAT" : "Imposto (escluso IVA)",
		"vatNotIncluded": "(escluso IVA)",
		"iva": "IVA (22%)",
		"orderSummary": "Riepilogo"
	},
	"en": {
		"pageTitle": "Offer Details",
		"completeOrder": "Buy",
		"confirmSendOrderTextTitle": "Confirm your order",
		"confirmSendOrderText": "Are you sure that you want to send your order",
		"confirmSendApprovalOrderTextTitle": "Send selected images",
		"confirmSendApprovalOrderText": "Are you sure you want to submit your selection?",
		"termsError": "You must accept the terms and conditions",
		"retouchingNotes": "Description",
		"requestRetouching": "Post-production request",
		"postProductionHint":"Write the changes required for each image",
		"sendWithRetouchingRequest": "Request changes",
		"approveOffer": "Approve",
		"titleSteps": "How does it work?",
		"revokedOffer": "The offer is no longer active.",
		"offerReceived": "Received on ",
		"proceedToOrder": "Proceed to order",
		"proceed": "Proceed",
		"youAreAboutToBuy": "You are about to buy {count} photo(s)",
		"youAreAboutToApprove": "You are about to approve {count} photo(s)",
		"youAreAboutToBuyVideo": "You are about to buy",
		"youAreAboutToApproveVideo": "You are about to approve {count} video(s)",
		"acceptTermsConditions": "Accept terms and conditions",
		"priceExcludingVAT" : "Price (excluding VAT)",
		"vatNotIncluded": "(VAT excluded)",
		"iva": "VAT (22%)",
		"orderSummary": "Order Summary"
	}
}
</i18n>
