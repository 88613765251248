<template>
	<v-dialog fullscreen :value="value" @input="$emit('input', $event)" @keydown.esc="HideImageDialog"
		:scrollable="false">
		<v-toolbar dense float color="" v-if="carouselCurrentIndex >= 0">
			<v-btn icon @click="HideImageDialog">
				<v-icon>fas fa-arrow-left</v-icon>
			</v-btn>

			<v-toolbar-title>{{ selectedFileName }}</v-toolbar-title>

			<v-spacer></v-spacer>
			<template>
				<v-btn v-if="Files[carouselCurrentIndex].MediaTypeId === 1" color="info"
					:href="Files[carouselCurrentIndex].Src + SasToken" target="_blank"><v-icon left>fas
						fa-cloud-download</v-icon> {{ $t("common.download") }}
				</v-btn>
				<v-btn v-else-if="Files[carouselCurrentIndex].MediaTypeId === 2" color="info"
					class="text-transform-none" :href="Files[carouselCurrentIndex].ExternalMediaUrl"
					target="_blank"><v-icon left>fas
						fa-external-link</v-icon> {{ $t("sgp.downloadOnVimeo") }}
				</v-btn>
			</template>
		</v-toolbar>
		<v-container fill-height fluid class="black fullscreenContainer">
			<v-row align="center" justify="center">
				<v-col class="pa-0" cols="12">
					<v-carousel height="auto" v-bind:value="carouselCurrentIndex" @change="OnCarouselCurrentIndex"
						:continuous="false" :hide-delimiters="true" class="" prev-icon="fas fa-angle-left"
						next-icon="fas fa-angle-right">
						<v-carousel-item v-for="(file, i) in Files" :key="i">
							<v-row class="fill-height ma-0 fullscreenContainer" align="center" justify="center">
								<div v-if="file.MediaTypeId === 2">
									<vueVimeoPlayer class="vimeo" :video-url="file.ExternalMediaUrl">
									</vueVimeoPlayer>
								</div>
								<v-img class="carouselImage" contain v-if="file.MediaTypeId === 1" :key="file.MediaId"
									:src="file.Src + SasToken" :lazy-src="file.PreviewSrc + SasToken"><template
										v-slot:placeholder>
										<v-row class="fill-height ma-0" align="center" justify="center">
											<v-progress-circular indeterminate
												color="grey lighten-2"></v-progress-circular>
										</v-row>
									</template>
								</v-img>
							</v-row>
						</v-carousel-item>
					</v-carousel>
				</v-col>
			</v-row>
		</v-container>
	</v-dialog>
</template>
<script>
import { vueVimeoPlayer } from "vue-vimeo-player";
import { mapActions, mapGetters } from "vuex";
// import OfferImage from "@/components/Shared/UI/OfferImage.vue";

export default {
	name: "OrderGalleryDialog",
	components: {
		vueVimeoPlayer,
		//	OfferImage,
	},
	props: {
		value: { type: Boolean, required: true },
		Files: { type: Array, required: true },
		carouselCurrentIndex: { type: Number },
	},
	data() {
		return {
			// Pass the editor default configuration options
			playerWidth: 400,
			playerHeight: 300,
		};
	},
	computed: {
		...mapGetters(["SasToken"]),

		selectedFileName() {
			if (this.Files[this.carouselCurrentIndex]) {
				// remove extension
				return this.Files[this.carouselCurrentIndex].FileName.replace(".json", "");
			}
			return null;
		},
	},
	watch: {
		value: async function (val) {
			this.$log.debug("watch value");
			this.$log.debug(val);

			// if (!val) this.ClearNewUserDialogForm();
		},
	},
	mounted() {
		this.$log.debug("OrderGalleryDialog loaded");
		this.$log.debug(this.Files);
	},

	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		HideImageDialog() {
			this.$emit("input", false);
		},

		OnCarouselCurrentIndex(param) {
			this.$emit("carousel-current-index-changed", param);

			//
		},
		carouselResize() {
			this.$log.debug("carouselResize");
		},
	},
};
</script>
<style lang="scss">
.vimeo iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
</style>
<style lang="scss" scoped>
.fullscreenContainer {
	height: calc(100vh - 48px) !important;
	/* max-height: calc(100vh-48px); */
}

.carouselImage {
	width: 100%;
	height: 100%;
	object-fit: contain;
	display: block;
}
</style>
