<template>
	<div>
		<confirm ref="confirm"></confirm>
		<div>
			<vue-headful v-if="offerDetails" :title="offerDetails.Title" />
			<v-breadcrumbs :items="breadcrumbsItems">
				<template v-slot:divider>
					<v-icon class="secondary--text text--lighten-2"
						>fas fa-caret-right</v-icon
					>
				</template>
			</v-breadcrumbs>

			<v-alert type="info" v-if="offerDetails.EnableThirdPartyApproval">
				<v-text-field
					:value="this.ApproverUrl"
					readonly
					append-icon="fas fa-copy"
					@click:append="CopyApproverUrl"
					label="Link per Approvazione"
				></v-text-field>
			</v-alert>

			<v-alert text type="info" dismissible v-if="offerDetails">
				<span class="font-weight-bold">{{ $t("titleSteps") }}</span>
				<br />
				<template v-if="isVideoOrder">
					<template v-if="offerDetails.OfferType === SgpOfferTypeEnum.OFFER">
						{{ $t("offerVideoStep1") }}
						<br />
						{{ $t("offerVideoStep2") }}
						<br />
						{{ $t("offerVideoStep3") }}
					</template>
					<template v-else>
						{{ $t("approvalVideoStep1") }}
						<br />
						{{ $t("approvalVideoStep2") }}
						<br />
						{{ $t("approvalVideoStep3") }}
					</template>
				</template>
				<template v-else>
					<template v-if="offerDetails.OfferType === SgpOfferTypeEnum.OFFER">
						{{ $t("offerStep1") }}
						<br />
						{{ $t("offerStep2") }}
						<br />
						{{ $t("offerStep3") }}
					</template>
					<template v-else>
						{{ $t("approvalStep1") }}
						<br />
						{{ $t("approvalStep2") }}
						<br />
						{{ $t("approvalStep3") }}
					</template>
				</template>
			</v-alert>
			<v-progress-linear
				indeterminate
				v-if="LoadingOfferDetails"
			></v-progress-linear>
			<v-card class="my-2 pa-2" v-if="offerDetails && clientSettings">
				<v-alert
					v-if="offerDetails.StatusId === SgpOfferStatusIdEnum.REVOKED"
					type="warning"
				>
					{{ $t("revokedOffer") }}
				</v-alert>

				<v-card-text v-else>
					<div class="text-h5">{{ offerDetails.Title }}</div>
					<div class="mb-2">
						<span class="text-overline">
							{{ $t("sgp.offer") }} {{ offerDetails.OfferId }}</span
						>
						| | {{ $t("offerReceived") }}
						{{ offerDetails.CreateDate | formatDate }}
					</div>
					<PhotoGrid
						:Files="offerDetails.SGPOfferItems"
						class="mt-5"
						:targetHeight="$vuetify.breakpoint.xs ? 140 : 200"
					>
						<template v-slot:cell="{ file, index }">
							<SelectableItem
								v-model="file.Selected"
								:disabled="
									[
										SgpApprovalOfferItemStatusEnum.REJECTED,
										SgpApprovalOfferItemStatusEnum.NOT_SET
									].includes(file.SgpApprovalOfferItem?.ApprovalStatus)
								"
							>
								<OfferImage
									v-if="file.MediaTypeId === 1"
									@click="ShowGalleryDialog(index)"
									:file="file"
								>
									<div class="ApprovalInfo" v-if="file.SgpApprovalOfferItem">
										<ApproveStatusIcon
											:size="18"
											:approve-status="file.SgpApprovalOfferItem.ApprovalStatus"
										></ApproveStatusIcon>

										{{ file.SgpApprovalOfferItem.RetouchingNotes }}
									</div>
									<div class="ArtistInfo" v-if="file.ArtistName">
										{{ file.ArtistName }}
									</div>
								</OfferImage>
								<VimeoThumbnail
									v-else
									@click="ShowGalleryDialog(index)"
									:src="file.ExternalPreviewUrl"
									:alt="file.FileName"
								>
								</VimeoThumbnail>
							</SelectableItem>
						</template>
					</PhotoGrid>

					<OfferGalleryDialog
						:Files="offerDetails.SGPOfferItems"
						v-model="ShowGallery"
						v-bind:carouselCurrentIndex="carouselCurrentIndex"
						@carousel-current-index-changed="OnCarouselIndexChanged($event)"
					></OfferGalleryDialog>

					<div class="my-5 text-center" v-if="offerDetails">
						<v-btn
							class="primary"
							large
							:disabled="!offerDetails.SGPOfferItems.find(i => i.Selected)"
							@click="showCreateOrderDialog = true"
						>
							{{
								offerDetails.OfferType === SgpOfferTypeEnum.OFFER
									? $t("proceedToOrder")
									: $t("proceed")
							}}
						</v-btn>
					</div>
					<CreateOrderDialog
						v-model="showCreateOrderDialog"
						:SelectedItems="SelectedItems"
						:clientSettings="clientSettings"
						:offerDetails="offerDetails"
					>
					</CreateOrderDialog>
				</v-card-text>
			</v-card>
		</div>
	</div>
</template>
<script>
import CrudClient from "@/services/CrudClient/";
import { mapGetters, mapActions } from "vuex";

import OfferGalleryDialog from "./OfferGalleryDialog.vue";
import SelectableItem from "@/components/Shared/Common/SelectableItem.vue";
import PhotoGrid from "@/components/Shared/UI/PhotoGrid.vue";
import VimeoThumbnail from "@/components/Shared/UI/vimeoThumbnail.vue";
import OfferImage from "@/components/Shared/UI/OfferImage.vue";
import Confirm from "@/components/Shared/Common/Confirm";

import { SgpOfferTypeEnum } from "../Events/components/sgpOfferTypesEnum.js";
import { SgpOfferStatusIdEnum } from "../Events/components/sgpOfferStatus";

import CreateOrderDialog from "./components/CreateOrderDialog";
import ApproveStatusIcon from "@/views/ThirdPartyApprover/ApproveStatusIcon.vue";
import { SgpApprovalOfferItemStatusEnum } from "@/views/Events/components/sgpApprovalOfferItemStatus";

export default {
	components: {
		ApproveStatusIcon,
		OfferGalleryDialog,
		CreateOrderDialog,
		SelectableItem,
		PhotoGrid,
		VimeoThumbnail,
		OfferImage,
		Confirm
	},
	data() {
		return {
			offerDetails: null,
			ApproverUrl: null,
			clientSettings: null,
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/"
				}
			],

			LoadingOfferDetails: false,
			// showActivationDialog: false,
			showPhotoGrid: false,
			showCreateOrderDialog: false,

			ShowGallery: false,
			carouselCurrentIndex: 0
		};
	},

	computed: {
		...mapGetters(["SasToken"]),
		OfferId() {
			return parseInt(this.$route.params.offerId);
		},
		SelectedItems() {
			return this.offerDetails?.SGPOfferItems.filter(i => i.Selected);
		},
		isVideoOrder() {
			if (this.offerDetails.length === 0) return false;

			return this.offerDetails.SGPOfferItems[0].MediaTypeId === 2;
		}
	},
	watch: {},
	created() {
		this.OffersService = new CrudClient("SGP/Client/Offers");
		this.ClientSettingsService = new CrudClient("SGP/ClientSettings");
		this.ClientOrdersService = new CrudClient("SGP/Client/Orders");
		this.ClientApproverOffersService = new CrudClient(
			"SGP/Client/ApproverOffers"
		);

		this.SgpOfferTypeEnum = SgpOfferTypeEnum;
		this.SgpOfferStatusIdEnum = SgpOfferStatusIdEnum;
		this.SgpApprovalOfferItemStatusEnum = SgpApprovalOfferItemStatusEnum;
	},
	async mounted() {
		try {
			this.LoadingOfferDetails = true;
			this.offerDetails = await this.OffersService.GetSelectedFields(
				this.OfferId,
				`*, SGPOfferStatus.*, SGPOfferPrices.*,
				SGPOfferPrices.PhotographyLicense.*,
				SGPOfferPrices.PhotographyLicense.PhotographyLicenseArea.*,
				SGPOfferItems.*,
				SGPOfferItems.SgpApprovalOfferItem.*`
			);

			if (this.offerDetails.EnableThirdPartyApproval) {
				this.ApproverUrl = await this.ClientApproverOffersService.Get(
					this.OfferId,
					"ApproverUrl"
				);
			}

			this.clientSettings = await this.ClientSettingsService.GetCustom(
				"MySettings"
			);

			// Order Items by file name
			this.offerDetails.SGPOfferItems = this.offerDetails.SGPOfferItems.sort(
				(a, b) =>
					a.FileName > b.FileName ? 1 : b.FileName > a.FileName ? -1 : 0
			);

			this.$log.debug("SGPOfferItems sorted");
		} catch (error) {
			this.$captureError(error);
			this.snackError({ Text: this.$t("error.cannotLoadData") });

			return;
		} finally {
			this.LoadingOfferDetails = false;
		}

		this.offerDetails.SGPOfferItems.forEach(oi =>
			this.$set(oi, "Selected", false)
		);

		this.breadcrumbsItems.push({
			text: this.$t("sgp.offers"),
			disabled: false,
			exact: true,
			to: "/Clients/Offers"
		});

		this.breadcrumbsItems.push({
			text: this.offerDetails.Title,
			disabled: true,
			exact: true,
			to: "/Clients/Offers/" + this.OfferId
		});
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		ShowGalleryDialog(index) {
			this.ShowGallery = true;
			this.carouselCurrentIndex = index;
		},
		OnCarouselIndexChanged(index) {
			this.carouselCurrentIndex = index;
		},

		CopyApproverUrl() {
			navigator.clipboard.writeText(this.ApproverUrl);
			this.snackSuccess({ Text: "Url copied!" });
		}
	}
};
</script>
<style lang="scss" scoped>
.darkened-image {
	filter: brightness(100%);
	transition: 0.3s;
	&:hover {
		filter: brightness(80%);
	}
}

.ArtistInfo {
	position: absolute;
	bottom: 5px;
	right: 5px;
	background-color: black;
	opacity: 0.5;
	padding: 2px 5px;
	border-radius: 4px;
	color: white;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 90%;
}

.ApprovalInfo {
	position: absolute;
	top: 5px;
	right: 5px;
	background-color: black;
	opacity: 0.8;
	padding: 2px 5px;
	border-radius: 4px;
	color: white;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 90%;
}
</style>
<i18n>
{
	"it": {
		"pageTitle": "Dettaglio Proposta",
		"termsError": "Devi accettare i termini e le condizioni",
		"titleSteps": "Come funziona?",

		"offerStep1": "1. Visualizza l'anteprima cliccando sull'immagine",
		"offerStep2": "2. Seleziona le immagini che vuoi acquistare",
		"offerStep3": "3. Procedi all'ordine",

		"approvalStep1": "1. Visualizza l'anteprima cliccando sull'immagine",
		"approvalStep2": "2. Seleziona le immagini",
		"approvalStep3": "3. Specifica l’eventuale Post-Produzione",

		"offerVideoStep1": "1. Visualizza l'anteprima cliccando sul video",
		"offerVideoStep2": "2. Seleziona il video che vuoi acquistare",
		"offerVideoStep3": "3. Procedi all'ordine",

		"approvalVideoStep1": "1. Visualizza l'anteprima cliccando sul video",
		"approvalVideoStep2": "2. Seleziona i video",
		"approvalVideoStep3": "3. Specifica l’eventuale Post-Produzione",

		"revokedOffer": "La proposta non è più attiva.",
		"offerReceived": "Ricevuto il ",
		"proceedToOrder": "Procedi all'ordine",
		"proceed": "Procedi"
	},
	"en": {
		"pageTitle": "Offer Details",
		"titleSteps": "How does it work?",
		"offerStep1": "1. Preview by clicking on the image",
		"offerStep2": "2. Select the images you want to buy",
		"offerStep3": "3. Proceed to order",

		"approvalStep1": "1. Preview by clicking on the image",
		"approvalStep2": "2. Select the images",
		"approvalStep3": "3. Specify any Post-Production",

		"offerVideoStep1": "1. Preview by clicking on the video",
		"offerVideoStep2": "2. Select the videos you want to buy",
		"offerVideoStep3": "3. Proceed to order",

		"approvalVideoStep1": "1. Preview by clicking on the video",
		"approvalVideoStep2": "2. Select the videos",
		"approvalVideoStep3": "3. Specify any Post-Production",

		"revokedOffer": "The offer is no longer active.",
		"offerReceived": "Received on ",
		"proceedToOrder": "Proceed to order",
		"proceed": "Proceed"
	}
}
</i18n>
