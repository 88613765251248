<template>
	<div>
		<vue-headful :title="$t('pageTitle')" :description="$t('pageTitle')" />
		<v-breadcrumbs :items="breadcrumbsItems">
      <template v-slot:divider>
			<v-icon class="secondary--text text--lighten-2"
				>fas fa-caret-right</v-icon
			>
      </template>
		</v-breadcrumbs>

		<h1 :class="$vuetify.breakpoint.xs ? 'text-h3 mt-0 mb-2' : 'text-h2'">
			{{ $t("pageTitle") }}
		</h1>

		<v-alert dark color="info" v-if="!loadingOrders && eventOrders.length === 0"
			>{{ $t("NoOrdersFound") }}
		</v-alert>
		<v-progress-linear indeterminate v-if="loadingOrders"></v-progress-linear>
		<div ref="offerPagingStart"></div>
		<OrderCard
			v-for="order in eventOrders"
			:key="order.OrderId"
			:order="order"
			@orderUpdated="LoadClientOrders"
		></OrderCard>

		<div class="text-center" v-if="itemsPaging">
			<v-pagination
				:disabled="loadingOrders"
				v-model="currentPage"
				:length="itemsPaging.TotalPages"
				:total-visible="5"
			></v-pagination>
		</div>
	</div>
</template>
<script>
import CrudClient from "@/services/CrudClient/";

import OrderCard from "./OrderCard.vue";

import { mapGetters, mapActions } from "vuex";

export default {
	components: { OrderCard },
	data() {
		return {
			loadingOrders: false,
			eventOrders: [],
			itemsPaging: null, // TotalItems, Limit, Offset, CurrentPageIndex, TotalPages
			currentPage: 1,
			pageSize: 5,
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},

				{
					text: "Ordini",
					disabled: true,
					exact: true,
					to: "/Clients/Orders",
				},
			],
		};
	},
	props: {},
	computed: {
		...mapGetters(["SasToken"]),
	},
	watch: {
		async currentPage() {
			await this.LoadClientOrders();
			this.scrollToElement("offerPagingStart");
		},
	},
	created() {
		this.OrdersService = new CrudClient("SGP/Client/Orders");
	},
	async mounted() {
		await this.LoadClientOrders();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async LoadClientOrders() {
			try {
				this.loadingOrders = true;
				const res = await this.OrdersService.GetPaged({
					limit: this.pageSize,
					skip: (this.currentPage - 1) * this.pageSize,
					orderBy: "OrderId:desc",
					fields: `*,
					SGPOrderStatus.*,
					SGPOrderItems.*,
					SGPOrderItems.PhotographyLicense.*,
					SGPEvent.Name, SGPEvent.EventDate,
					SGPOffer.OfferType
					`,
				});

				this.eventOrders = res.Data;
				this.itemsPaging = res.Paging;
				this.currentPage = res.Paging.CurrentPageIndex + 1;
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			} finally {
				this.loadingOrders = false;
			}
		},

		scrollToElement(element, container, enableSmooth = true) {
			if (this.$refs[element]) {
				(container || window).scrollTo({
					top: this.$refs[element].offsetTop,
					left: 0,
					behavior: enableSmooth ? "smooth" : undefined,
				});
			}
		},
	},
};
</script>
<i18n>
{
	"it": {
		"pageTitle": "Ordini",
		"NoOrdersFound": "Nessun ordine trovato"
	},
	"en": {
		"pageTitle": "Orders",
		"NoOrdersFound": "No orders found"
	}
}
</i18n>
