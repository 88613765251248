<template>
	<v-tooltip bottom>
		<template v-slot:activator="{ on, attrs }">
			<v-icon color="info" size="18" class="ml-3" v-bind="attrs" v-on="on">fas fa-info-circle</v-icon>
		</template>
		<span>
			{{ ($root.$i18n.locale === "it") ? PhotographyLicense.Name : PhotographyLicense.NameEN }}
			<br />

			{{ ($root.$i18n.locale === "it") ? PhotographyLicense.Description : PhotographyLicense.DescriptionEN }}
			<br />
			{{$t("area")}}:
			<span v-if="PhotographyLicense.PhotographyLicenseArea">{{ PhotographyLicense.PhotographyLicenseArea.Name |
				emptyField }}
			</span>
			<span v-else>-</span>
			<br />
			{{ $t("duration") }}:
			<span v-if="PhotographyLicense.ExpiresInMonths">{{ PhotographyLicense.ExpiresInMonths }} {{ $t("months") }}</span>
			<span v-else>lifetime</span>
		</span>
	</v-tooltip>
</template>
<script>
export default {
	props: {
		PhotographyLicense: { type: Object, required: true },
	},
};
</script>
<i18n>
	{
		"it":{
			"area":"Luogo",
			"duration": "Durata",
			"months": "mesi"
		},
		"en": {
			"area":"Location",
			"duration": "Duration",
			"months": "months"
		}
	}
</i18n>
