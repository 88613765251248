<template>
	<v-card class="mb-5 elevateOnHover">
		<confirm ref="confirm"></confirm>
		<v-card-text :class="$vuetify.breakpoint.xs ? 'pa-3' : ''">
			<v-row>
				<v-col cols="12" md="4" order-md="2" class="text-right" v-if="ShowDownloadZip">
					<v-btn v-if="order.ZipCreated" color="info" :href="order.ZipSrc + SasToken"
						:block="$vuetify.breakpoint.xs" target="_blank"><v-icon left>fas fa-cloud-download</v-icon> {{
						$t("downloadAll") }}</v-btn>
					<v-btn v-else color="info lighten-3" :block="$vuetify.breakpoint.xs" target="_blank"><v-icon
							left>fas fa-cloud-download</v-icon> {{ $t("zipInProgress") }}
					</v-btn>
				</v-col>
				<v-col cols="12" md="8" order-md="1">
					<span class="text-overline">{{ $t("sgp.orderId") }} {{ order.OrderId }}
					</span>
					<sgpOrderStatusChip :StatusId="order.StatusId"></sgpOrderStatusChip>
					<v-chip v-if="
							order.SGPOffer && order.SGPOffer.OfferType === SgpOfferTypeEnum.APPROVAL
						" color="purple" class="ml-3" dark>
						<v-icon left x-small>fas fa-shield-check </v-icon>
						{{ $t("sgp.offerForApproval") }}</v-chip>

					<br />

					<span class="font-weight-bold pr-1">{{ $t("orderDate") }}:</span>
					{{ order.CreateDate | formatDate }}
					<br />

					<span class="font-weight-bold pr-1">{{ $t("sgp.event") }}:</span>
					{{ order.SGPEvent.Name }} del
					{{ (order.ExactEventDate || order.SGPEvent.EventDate) | formatDate }}
					<br />

					<template v-if="order.SGPOrderItems.length > 0 && order.SGPOrderItems[0].LicenseId">
						<span class="font-weight-bold pr-1">{{ $t("sgp.license") }}:</span>
						{{ order.SGPOrderItems[0].PhotographyLicense.Name }}

						<LicenseInfoHelpIcon :PhotographyLicense="order.SGPOrderItems[0].PhotographyLicense">
						</LicenseInfoHelpIcon>
						<br />
						<span class="font-weight-bold pr-1">{{ $t("common.expiration") }}:</span>
						{{ order.SGPOrderItems[0].LicenseExpireDate | formatDate }}

						<br />
						<template v-if="order.CalculateTotalByItems">
							<span class="font-weight-bold pr-1">{{ $t("common.price") }}:</span>
							{{ order.SGPOrderItems.length }} x
							{{ order.SGPOrderItems[0].Price | formatCurrency }}

							<br />
						</template>
					</template>
					<template v-if="order.Total > 0">
						<span class="font-weight-bold pr-1">{{ $t("common.total") }}:</span>
						{{ order.Total | formatCurrency }} {{ $t("sgp.plusVAT") }}</template>
				</v-col>
			</v-row>

			<template v-if="
					order.StatusId === SgpOrderStatusIdEnum.SENT ||
					order.StatusId === SgpOrderStatusIdEnum.APPROVED ||
					order.StatusId === SgpOrderStatusIdEnum.WAITING_APPROVAL
				">
				<PhotoGrid :Files="order.SGPOrderItems" class="mt-5" :targetHeight="$vuetify.breakpoint.xs ? 140 : 200">
					<template v-slot:cell="{ file, index }">
						<OrderImage v-if="file.MediaTypeId === 1" :file="file" @click="ShowGalleryDialog(index)">
							<div v-if="requestRetouchingsMode" class="pa-3 black white--text" style="opacity: 0.6">
								{{ file.FileName }}
							</div>
						</OrderImage>
						<VimeoThumbnail v-else-if="file.MediaTypeId === 2" :src="file.ExternalPreviewUrl"
							@click="ShowGalleryDialog(index)" :alt="file.FileName">
						</VimeoThumbnail>
						<div class="text-center">
							<v-btn v-if="file.MediaTypeId === 2" color="info" class="text-transform-none mt-2"
															small :href="file.ExternalMediaUrl" target="_blank"><v-icon left>fas
									fa-external-link</v-icon> {{ $t("sgp.downloadOnVimeo") }}
							</v-btn>
						</div>
					</template>
				</PhotoGrid>
				<OrderGalleryDialog v-model="showGallery" :Files="order.SGPOrderItems"
					v-bind:carouselCurrentIndex="carouselCurrentIndex"
					@carousel-current-index-changed="OnCarouselIndexChanged($event)"></OrderGalleryDialog>
			</template>
			<template v-else-if="order.StatusId === SgpOrderStatusIdEnum.INPOSTPROCESSING">
				<v-alert color="info" dark icon="fas fa-stars" border="left">
					Ordine in post-produzione
					<br />
					Riceverai una notifica appena l'ordine sarà pronto.
				</v-alert>
			</template>

			<template v-if="requestRetouchingsMode">
				<v-textarea v-if="requestRetouchingsMode" v-model="retouchingNotes" counter="1000" solo
					:hint="$t('postProductionHint')"></v-textarea>
			</template>
		</v-card-text>
		<v-card-actions v-if="order.StatusId === SgpOrderStatusIdEnum.WAITING_APPROVAL">
			<template>
				<v-spacer></v-spacer>
				<template v-if="!requestRetouchingsMode">
					<v-btn color="info" class="text-capitalize" text :disabled="SendingRequest"
						@click="InitRequestRetouchings">{{ $t("requestRetouching") }}</v-btn>
					<v-btn color="info" class="text-capitalize" @click="ApproveOrder" :loading="SendingRequest">
						<v-icon small left>far fa-check</v-icon>
						{{ $t("approveOrder") }}</v-btn>
				</template>
				<template v-else>
					<v-btn color="info" text @click="requestRetouchingsMode = false"
						:disabled="SendingRetouchingRequest">{{ $t("common.cancel") }}</v-btn>
					<v-btn color="info" @click="SendRetouchingRequest" :loading="SendingRetouchingRequest">{{
						$t("requestRetouching") }}</v-btn>
				</template>
			</template>
		</v-card-actions>
	</v-card>
</template>
<script>
import Confirm from "@/components/Shared/Common/Confirm";
import PhotoGrid from "@/components/Shared/UI/PhotoGrid.vue";
import VimeoThumbnail from "@/components/Shared/UI/vimeoThumbnail.vue";
import OrderImage from "@/components/Shared/UI/OrderImage.vue";

import LicenseInfoHelpIcon from "@/components/PhotographyLicenses/LicenseInfoHelpIcon.vue";

import OrderGalleryDialog from "./OrderGalleryDialog.vue";

import sgpOrderStatusChip from "../Events/components/sgpOrderStatusChip.vue";
import { mapGetters, mapActions } from "vuex";

import { SgpOfferTypeEnum } from "../Events/components/sgpOfferTypesEnum";
import { SgpOrderStatusIdEnum } from "../Events/components/sgpOrderStatus";
import CrudClient from "@/services/CrudClient/";

export default {
	name: "ClientOrderCard",
	components: {
		PhotoGrid,
		VimeoThumbnail,
		OrderImage,
		OrderGalleryDialog,
		LicenseInfoHelpIcon,
		sgpOrderStatusChip,
		Confirm,
	},
	props: {
		order: { type: Object, required: true },
	},
	data() {
		return {
			showGallery: false,
			carouselCurrentIndex: 0,
			SendingRequest: false,
			requestRetouchingsMode: false,
			SendingRetouchingRequest: false,
			retouchingNotes: null,
		};
	},
	computed: {
		...mapGetters(["SasToken"]),

		ShowDownloadZip() {
			if (this.order.SGPOrderItems.find(i => i.MediaTypeId === 2)) {
				return false;
			}

			// order is sent and is a client order (not direct delivery or approval)
			return this.order.StatusId === SgpOrderStatusIdEnum.SENT &&
				(!this.order.SGPOffer || this.order.SGPOffer.OfferType === SgpOfferTypeEnum.OFFER);
		}
	},
	created() {
		this.OrdersService = new CrudClient("SGP/Client/Orders");
		this.SgpOfferTypeEnum = SgpOfferTypeEnum;
		this.SgpOrderStatusIdEnum = SgpOrderStatusIdEnum;
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),
		ShowGalleryDialog(index) {
			this.showGallery = true;
			this.carouselCurrentIndex = index;
		},
		OnCarouselIndexChanged(index) {
			this.carouselCurrentIndex = index;
		},

		async ApproveOrder() {
			if (
				!(await this.$refs.confirm.open(
					this.$t("confirmApprove"),
					this.$t("confirmApproveText")
				))
			) {
				return;
			}

			this.SendingRequest = true;
			try {
				await this.OrdersService.Post(this.order.OrderId, null, "approve", false);
				this.$emit("orderUpdated");
				this.snackSuccess({ Text: "Immagine Approvate!" });
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			} finally {
				this.SendingRequest = false;
			}
		},

		async SendRetouchingRequest() {
			if (
				!(await this.$refs.confirm.open(
					this.$t("confirmRetouchingRequest"),
					this.$t("confirmRetouchingRequestText")
				))
			) {
				return;
			}

			const dto = {
				RetouchingNotes: this.retouchingNotes,
			};

			this.SendingRetouchingRequest = true;
			try {
				await this.OrdersService.Post(
					this.order.OrderId,
					dto,
					"requestRetouch",
					false
				);
				this.snackSuccess({ Text: "Richiesta inviata" });
				this.$emit("orderUpdated");
				this.requestRetouchingsMode = false;
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			} finally {
				this.SendingRetouchingRequest = false;
			}
		},

		PrepareRequestMessage() {
			this.retouchingNotes = "";
			for (let i = 0; i < this.order.SGPOrderItems.length; i++) {
				this.retouchingNotes += this.order.SGPOrderItems[i].FileName + ": \n\n";
			}
		},

		async InitRequestRetouchings(order) {
			this.PrepareRequestMessage();

			this.requestRetouchingsMode = true;
		},
	},
};
</script>
<i18n>
{
	"it": {
		"orderDate": "Data ordine",
		"approveOrder": "Approva",
		"requestRetouching": "Richiedi ritocchi",
		"confirmApprove": "Approva le immagini",
		"confirmApproveText": "Sei sicuro di voler approvare le immagini?",
		"postProductionHint":"Scrivi le modifiche richieste per ogni immagine",
		"confirmRetouchingRequest": "Richiedi ritocchi",
		"confirmRetouchingRequestText": "Sei sicuro di voler inviare la richiesta di ritocco?",
		"requestRetouching": "Richiedi ritocchi",
		"downloadAll": "Scarica tutte le foto",
		"zipInProgress": "Zip in preparazione"
	},
	"en": {
		"orderDate": "Order date",
		"approveOrder": "Approve",
		"requestRetouching": "Request photo retouching",
		"confirmApprove": "Approve the images",
		"confirmApproveText": "Are you sure that you want to approve the images?",
		"postProductionHint":"Write the changes required for each image",
		"confirmRetouchingRequest": "Request photo retouching",
		"confirmRetouchingRequestText": "Are you sure you want to send the retouch request?",
		"requestRetouching": "Request retouching",
		"downloadAll": "Download all photos",
		"zipInProgress": "Preparing the zip file"
	}
}
</i18n>
