<template>
	<v-card outlined>
		<v-card-text>
			<v-overlay :value="SendingOrder">
				<v-progress-circular indeterminate size="64"></v-progress-circular>
			</v-overlay>
			<div class="basketLargeLabel mb-2">
				{{ $t("billingInfo") }}
			</div>
			<v-row dense>
				<v-divider class="my-3"></v-divider>
			</v-row>

			<v-select v-model="selectedBillingProfileId" :items="BillingProfiles" item-text="DisplayName"
				item-value="BillingProfileId" label="Profilo Fatturazione" :loading="LoadingBillingProfiles">
			</v-select>

			<div v-if="SelectedBillingProfile">
				<span class="font-weight-bold"> {{ $t("common.pIva") }}:</span>
				{{ SelectedBillingProfile.PartitaIVA }} <br />
				<template v-if="SelectedBillingProfile.CodiceFiscale">
					<span class="font-weight-bold"> {{ $t("common.fiscalCode") }}:</span>
					{{ SelectedBillingProfile.CodiceFiscale }} <br /></template>

				<span class="font-weight-bold"> {{ $t("common.address") }}:</span>
				{{ SelectedBillingProfile.Address }} {{ SelectedBillingProfile.PostalCode }}
				{{ SelectedBillingProfile.ComuneId ? SelectedBillingProfile.ItalianCity.Name :
				SelectedBillingProfile.NonItalianCityName }}
			</div>
			<v-btn @click="ShowNewBillingProfileDialog" color="primary" large block
				:text="BillingProfiles && BillingProfiles.length > 0" :loading="LoadingBillingProfiles">{{
				$t("common.add")
				}}</v-btn>
			<AddEditBillingProfile v-model="showAddBillingProfile" :mode="dialogData.Mode" :UserId="null"
				:BillingProfileId="dialogData.BillingProfileId" @updated="OnBillingProfileUpdated">
			</AddEditBillingProfile>

			<v-divider class="my-3"></v-divider>
			<div class="text-body-1">
				<v-row dense>
					<v-col><span class="basketLabel">{{ $t("taxableAmount") }}:</span></v-col>
					<v-col class="text-right">
						<span class="basketValue">{{ this.OrderTotal | formatCurrency
							}}</span>
					</v-col>
				</v-row>

				<v-row dense>
					<v-divider class="my-3"></v-divider>
				</v-row>
				<v-row dense>
					<v-col><span class="basketLabel">{{ $t("vat") }}:</span></v-col>
					<v-col class="text-right">
						<span class="basketValue">
							{{ this.VAT | formatCurrency }}
						</span>

					</v-col>
				</v-row>
				<v-row dense>
					<v-divider class="my-3"></v-divider>
				</v-row>
				<v-row dense>
					<v-col><span class="basketLabel">{{ $t("common.total") }}:</span></v-col>
					<v-col class="text-right">
						<span class="basketValue">
							{{ this.PaymentAmount | formatCurrency }}
						</span>

					</v-col>
				</v-row>
				<v-row dense>
					<v-divider class="my-3"></v-divider>
				</v-row>
			</div>

			<pay-pal-button v-if="selectedBillingProfileId && !SendingOrder" :amount="PayPalAmount" currency="EUR"
				:client="PayPal.credentials" :env="PayPal.enviroment"
				@paymentCompleted="OnPaymentComplete($event)"></pay-pal-button>

			<v-alert color="amber accent-2" border="left" icon="fas fa-lightbulb" dense>
				{{ $t("paypalNote") }}
			</v-alert>

		</v-card-text>
	</v-card>
</template>

<script>
import CrudClient from "@/services/CrudClient/";
import { SgpOfferTypeEnum } from "@/views/Events/components/sgpOfferTypesEnum.js";
import PayPalButton from "@/components/Shared/FormControl/PayPalButton";
import AddEditBillingProfile from "@/components/Account/AddEditBillingProfile";
import { mapActions } from "vuex";

export default {
	components: {
		PayPalButton,
		AddEditBillingProfile,
	},
	props: {
		OrderTotal: {
			type: Number,
			required: true,
		},
		SendingOrder: {
			type: Boolean,
		},
		VAT: {
			type: Number,
			required: true,
		},
	},
	data() {
		return {
			// PayPalAmount: "10",
			PayPal: {
				enviroment: process.env.VUE_APP_PAYPAL_ENV,
				credentials: {
					sandbox: process.env.VUE_APP_PAYPAL_SANDBOX_CLIENTID,
					production: process.env.VUE_APP_PAYPAL_PRODUCTION_CLIENTID,
				},
				style: {
					label: "checkout",
					size: "responsive",
					shape: "rect",
					color: "blue",
				},
				order: {
					description: "",
					amount: {
						currency_code: "EUR",
						value: 0,
					},
				},
			},
			showAddBillingProfile: false,
			dialogData: {
				Mode: 1,
				BillingProfileId: null,
			},
			LoadingBillingProfiles: false,
			BillingProfiles: null,
			selectedBillingProfileId: null,
		};
	},
	computed: {
		PaymentAmount() {
			return this.OrderTotal + this.VAT;
		},
		PayPalAmount() {
			return (Math.round(this.PaymentAmount * 100) / 100).toFixed(2);
		},
		SelectedBillingProfile() {
			if (this.BillingProfiles == null) return null;
			return this.BillingProfiles.find(
				(bp) => bp.BillingProfileId === this.selectedBillingProfileId
			);
		},
	},
	created() {
		this.BillingProfileService = new CrudClient("SGP/Client/BillingProfilesSelf");

		this.SgpOfferTypeEnum = SgpOfferTypeEnum;
	},
	async mounted() {
		await this.LoadUserBillingProfiles();
	},

	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		OnPaymentComplete(event) {
			this.$log.debug("Payment Complete");
			this.$log.debug(event);
			this.$emit("paymentComplete", event);
		},

		async OnBillingProfileUpdated() {
			this.showAddBillingProfile = false;
			await this.LoadUserBillingProfiles();
		},

		async LoadUserBillingProfiles() {
			try {
				this.LoadingBillingProfiles = true;
				const res = await this.BillingProfileService.GetPaged({
					limit: 0,
					fields: "*, ItalianCity.Name, Country.DescrizioneNazione",
					urlPostFix: "",
					orderBy: "BillingProfileId:desc",
					filter: "IsActive:true",
				});

				this.BillingProfiles = res.Data;

				if (this.BillingProfiles.length === 0) {
					this.ShowNewBillingProfileDialog();
					this.selectedBillingProfileId = null;
				} else {
					this.selectedBillingProfileId = this.BillingProfiles[0].BillingProfileId;
				}
			} catch (error) {
				this.snackError({ Text: this.$t("snackError_CannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingBillingProfiles = false;
			}
		},

		ShowNewBillingProfileDialog() {
			this.showAddBillingProfile = true;
			this.dialogData.Mode = 1;
		},
	},
};
</script>
<i18n>
{
	"it": {
		"billingInfo": "Dati di fatturazione",
		"taxableAmount": "Imponibile",
		"vat": "IVA",
		"paypalNote": "Sul circuito di PayPal puoi pagare anche con la tua carta di credito."
	},
	"en": {
		"billingInfo": "Billing information",
		"taxableAmount": "Taxable Amount",
		"vat": "VAT",
		"paypalNote": "Using the PayPal circuit you can pay using your credit card"
	}
}
</i18n>
