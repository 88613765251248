<template>
	<v-radio-group :value="value" @change="$emit('input', $event)" hide-details class="my-2">
		<v-radio v-for="offerprice in OfferDetails.SGPOfferPrices" :key="offerprice.LicenseId"
			:value="offerprice.LicenseId" class="pa-2 ma-0" :class="{
				'blue-grey lighten-4 rounded': offerprice.LicenseId === value
			}">
			<template v-slot:label>
				<v-container fluid class="my-0 py-0">
					<v-row dense>
						<v-col cols="9">
							<div class="basketLabel mb-1 mt-0">
								{{ ($root.$i18n.locale === "it") ?
								offerprice.PhotographyLicense.Name :
								offerprice.PhotographyLicense.NameEN }}
							</div>
							<!-- <div class="caption">
								{{ ($root.$i18n.locale === "it") ?
								offerprice.PhotographyLicense.Description :
								offerprice.PhotographyLicense.DescriptionEN }}
							</div> -->
							<div class="text-subtitle-2">
								{{ $t("area") }}:
								<span v-if="offerprice.PhotographyLicense.PhotographyLicenseArea">{{
									offerprice.PhotographyLicense.PhotographyLicenseArea.Name
									|
									emptyField }}
								</span>
								<span v-else>-</span>
								<span class="mx-2"></span>
								{{ $t("duration") }}:
								<span v-if="offerprice.PhotographyLicense.ExpiresInMonths">{{
									offerprice.PhotographyLicense.ExpiresInMonths }} {{
									$t("months")
									}}</span>
								<span v-else>lifetime</span>
							</div>
						</v-col>
						<v-col cols="3">
							<div class="basketLabel text-right">
								{{ offerprice.Price | formatCurrencyAvoidDecimal }}</div>
						</v-col>
					</v-row>

				</v-container>
			</template>
		</v-radio>
	</v-radio-group>
</template>
<script>
export default {
	props: {
		value: { type: Number },
		OfferDetails: { type: Object, required: true },
	},
}
</script>
<i18n>
	{
		"it": {
			"area":"Luogo",
			"duration": "Durata",
			"months": "mesi"
		},
		"en": {
			"duration": "Duration",
			"months": "months",
			"area":"Location"
		}
	}
</i18n>
