<template>
	<div>
		<vue-headful :title="$t('pageTitle')" :description="$t('pageTitle')" />
		<v-breadcrumbs :items="breadcrumbsItems">
      <template v-slot:divider>
			<v-icon class="secondary--text text--lighten-2">fas fa-caret-right</v-icon>
      </template>
		</v-breadcrumbs>
		<h1 :class="$vuetify.breakpoint.xs ? 'text-h3 mt-0 mb-2' : 'text-h2'">
			{{ $t("pageTitle") }}
		</h1>
		<v-alert dark color="info" v-if="!loadingOffers && eventOffers.length === 0">{{ $t("NoOffersForEvent") }}
		</v-alert>
		<v-progress-linear indeterminate v-if="loadingOffers"></v-progress-linear>
		<div ref="offerPagingStart"></div>
		<v-card class="mb-4 elevateOnHover" v-for="offer in eventOffers" :key="offer.OfferId"
			:to="'/Clients/Offers/' + offer.OfferId">
			<!-- <v-card-title>{{ offer.Title }}</v-card-title> -->
			<v-card-text :class="$vuetify.breakpoint.xs ? 'pa-3' : ''">
				<div class="text-h5">{{ offer.Title }}</div>
				<div class="mb-2">
					<span class="text-overline">
						{{ $t("sgp.offer") }} {{ offer.OfferId }}</span>
					| {{ $t("offerReceived") }}
					{{ offer.CreateDate | formatDate }}

					<v-chip v-if="offer.OfferType === SgpOfferTypeEnum.APPROVAL" color="purple" class="ml-3" dark>
						<v-icon left x-small>fas fa-shield-check </v-icon>
						{{ $t("sgp.offerForApproval") }}</v-chip>
				</div>

				<PhotoGrid :Files="offer.SGPOfferItems" class="mt-5" :targetHeight="$vuetify.breakpoint.xs ? 140 : 200">
					<template v-slot:cell="{ file }">
						<OfferImage v-if="file.MediaTypeId === 1" :file="file"></OfferImage>
						<VimeoThumbnail v-else-if="file.MediaTypeId === 2" :src="file.ExternalPreviewUrl"
							:alt="file.FileName">
						</VimeoThumbnail>
					</template>
				</PhotoGrid>
			</v-card-text>
		</v-card>
		<div class="text-center" v-if="itemsPaging">
			<v-pagination :disabled="loadingOffers" v-model="currentPage" :length="itemsPaging.TotalPages"
				:total-visible="5"></v-pagination>
		</div>
	</div>
</template>
<script>
import CrudClient from "@/services/CrudClient/";
import PhotoGrid from "@/components/Shared/UI/PhotoGrid.vue";

import VimeoThumbnail from "@/components/Shared/UI/vimeoThumbnail.vue";
import OfferImage from "@/components/Shared/UI/OfferImage.vue";
import { mapGetters, mapActions } from "vuex";
import { SgpOfferTypeEnum } from "../Events/components/sgpOfferTypesEnum";
export default {
	components: { PhotoGrid, VimeoThumbnail, OfferImage },
	data() {
		return {
			loadingOffers: false,
			eventOffers: [],
			itemsPaging: null, // TotalItems, Limit, Offset, CurrentPageIndex, TotalPages
			currentPage: 1,
			pageSize: 5,
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},

				{
					text: this.$t("pageTitle"),
					disabled: true,
					exact: true,
					to: "/Clients/Offers",
				},
			],
		};
	},
	props: {},
	computed: {
		...mapGetters(["SasToken"]),
	},
	watch: {
		async currentPage() {
			await this.LoadEventOffers();
			this.scrollToElement("offerPagingStart");
		},
	},
	created() {
		this.OffersService = new CrudClient("SGP/Client/Offers");
		this.SgpOfferTypeEnum = SgpOfferTypeEnum;
	},
	async mounted() {
		await this.LoadEventOffers();

		this.eventOffers.forEach((o) => this.$set(o, "ShowGallery", false));
		this.eventOffers.forEach((o) => this.$set(o, "carouselCurrentIndex", 0));
		this.eventOffers.forEach((o) => {
			o.SGPOfferItems.forEach((oi) => this.$set(oi, "Selected", false));
		});
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async LoadEventOffers() {
			try {
				this.loadingOffers = true;
				const res = await this.OffersService.GetPaged({
					limit: this.pageSize,
					skip: (this.currentPage - 1) * this.pageSize,
					orderBy: "OfferId:desc",
					fields:
						"*, SGPOfferStatus.*, SGPOfferPrices.*, SGPOfferPrices.PhotographyLicense.*, SGPOfferItems.*",
				});

				this.eventOffers = res.Data;
				this.itemsPaging = res.Paging;
				this.currentPage = res.Paging.CurrentPageIndex + 1;
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			} finally {
				this.loadingOffers = false;
			}
		},

		ShowGallery(offer, index) {
			offer.ShowGallery = true;
			offer.carouselCurrentIndex = index;
		},
		OnCarouselIndexChanged(offer, index) {
			offer.carouselCurrentIndex = index;
		},
		scrollToElement(element, container, enableSmooth = true) {
			if (this.$refs[element]) {
				(container || window).scrollTo({
					top: this.$refs[element].offsetTop,
					left: 0,
					behavior: enableSmooth ? "smooth" : undefined,
				});
			}
		},
	},
};
</script>
<style lang="scss" scoped>
.darkened-image {
	filter: brightness(100%);
	transition: 0.3s;
	&:hover {
		filter: brightness(80%);
	}
}
</style>
<i18n>
{
	"it": {
		"pageTitle": "Proposte Ricevute",
		"NoOffersForEvent": "Nessun proposta trovata",
		"offerReceived": "Ricevuto il "
	},
	"en": {
		"pageTitle": "Received Offers",
		"NoOffersForEvent": "No offers found",
		"offerReceived": "Received on "
	}
}
</i18n>
