<template>
	<div>
		<vue-headful :title="$t('pageTitle')" :description="$t('pageTitle')" />
		<v-breadcrumbs :items="breadcrumbsItems">
      <template v-slot:divider>
			<v-icon class="secondary--text text--lighten-2">fas fa-caret-right</v-icon>
      </template>
		</v-breadcrumbs>

		<h1 class="text-h4" v-if="ShowThanks">
			{{ $t("thanksMessage") }}
		</h1>
		<h1 class="text-h4" v-else-if="ShowThanksForApproval">
			{{ $t("thanksForApproval") }}
		</h1>
		<h1 class="text-h4" v-else>
			{{ $t("pageTitle") }}
		</h1>

		<v-progress-linear indeterminate v-if="loadingOrders"></v-progress-linear>
		<OrderCard v-if="orderDetails" :order="orderDetails" @orderUpdated="LoadClientOrders"></OrderCard>

		<v-alert v-if="orderDetails && ShowThanks" type="info">
			<span class="font-weight-medium text-h5 pt-0">
				{{ $t("billingInfoTitle") }}
			</span>
			<!-- {{ $t("InfoBillingDetails") }} -->
			<div class="mt-2">
				{{ $t("billingInfoText") }}

				<div v-if="IsPaymentMethodPayPal" class="mt-2">
					{{ $t("billingInfoContact") }}
					<a href="mailto:produzione@sgpitalia.com"
						class="white--text font-weight-medium">produzione@sgpitalia.com</a>
				</div>

			</div>
		</v-alert>
	</div>
</template>
<script>
import CrudClient from "@/services/CrudClient/";

import OrderCard from "./OrderCard.vue";

import { mapGetters, mapActions } from "vuex";

export default {
	components: { OrderCard },
	data() {
		return {
			loadingOrders: false,
			orderDetails: null,

			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},

				{
					text: "Ordini",
					disabled: false,
					exact: true,
					to: "/Clients/Orders",
				},
			],

			checkInterval: null,
		};
	},
	props: {},
	computed: {
		...mapGetters(["SasToken"]),

		OrderId() {
			return parseInt(this.$route.params.orderId);
		},

		ShowThanks() {
			return !!this.$route.query.ShowThanks;
		},
		ShowThanksForApproval() {
			return !!this.$route.query.ShowThanksForApproval;
		},

		IsPaymentMethodPayPal() {
			if (this.orderDetails === null) {
				return false;
			}
			return this.orderDetails.PaymentMethodId === 2;
		}
	},

	created() {
		this.OrdersService = new CrudClient("SGP/Client/Orders");
	},
	async mounted() {
		await this.LoadClientOrders();

		this.breadcrumbsItems.push({
			text: this.OrderId,
			disabled: true,
			exact: true,
			to: "/Clients/Orders/" + this.OrderId,
		});

		// Status Sent(2) and Zip not ready
		if (this.orderDetails.StatusId === 2 && !this.orderDetails.ZipCreated) {
			this.checkInterval = setInterval(this.CheckZipCreated, 4000);
		}
	},
	beforeDestroy() {
		this.ClearInterval();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		ClearInterval() {
			if (this.checkInterval) {
				clearInterval(this.checkInterval);
				this.checkInterval = null;
			}
		},
		async LoadClientOrders() {
			try {
				this.loadingOrders = true;
				this.orderDetails = await this.OrdersService.GetSelectedFields(
					this.OrderId,
					`*,
					SGPOrderStatus.*,
					SGPOrderItems.*,
					SGPOrderItems.PhotographyLicense.*,
					SGPEvent.Name, SGPEvent.EventDate,
					SGPOffer.OfferType
					`
				);
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
			} finally {
				this.loadingOrders = false;
			}
		},

		async CheckZipCreated() {
			try {
				const resData = await this.OrdersService.GetSelectedFields(
					this.OrderId,
					"ZipCreated"
				);

				if (resData.ZipCreated) {
					this.$log.debug("ZipReady");

					this.orderDetails.ZipCreated = true;
					this.ClearInterval();
				}
			} catch (error) {
				this.$captureError(error);
			}
		},
	},
};
</script>
<i18n>
{
	"it": {
		"pageTitle": "Dettagli ordine",
		"NoOrdersFound": "Nessun ordine trovato",
		"thanksForApproval":"Grazie per l'approvazione",
		"thanksMessage":"Grazie per il tuo acquisto",
		"billingInfoTitle": "Fatturazione",
		"billingInfoText": "Riceverai al più presto regolare fattura per il servizio",
		"billingInfoContact": "Per eventuali richieste di post-produzione puoi scrivere a "
	},
	"en": {
		"pageTitle": "Order details",
		"NoOrdersFound": "No orders found",
		"thanksForApproval":"Thanks for the approval",
		"thanksMessage":"Thank you for your purchase",
		"billingInfoTitle": "Invoice",
		"billingInfoText": "You will receive a regular invoice for the service",
		"billingInfoContact": "For any post-production requests, you can write to "
	}
}
</i18n>
